import lozad from "lozad";
import "slick-carousel";
import { swipeForWindows } from "javascripts/webpage/swipe-for-windows";
import { collapseFortravelReviews } from "javascripts/webpage/collapse-fortravel-reviews";
import { unmaskSlash } from "javascripts/webpage/link";

document.addEventListener("DOMContentLoaded", () => {
  function fetchSecondaryReviews() {
    const wrapper = document.querySelector(".js-secondary-reviews-wrapper");
    fetch(unmaskSlash(document.querySelector(".js-fetch-url").dataset.fetchUrl), { headers: { "X-Requested-With": "XMLHttpRequest" } })
      .then((response) => {
        if (!response.ok) {
          throw "ErrorException";
        }
        return response.json();
      })
      .then((data) => {
        if (data.html) {
          wrapper.innerHTML = data.html;
          const addedElements = wrapper.querySelectorAll(".js-lazy:not([data-loaded])");
          const newObserver = lozad(addedElements, {
            rootMargin: "200% 400%",
          });
          newObserver.observe();
          $(".js-secondary-reviews-wrapper .js-slick-photos").slick({
            arrows: true,
            cssEase: "linear",
            dots: false,
            fade: true,
            infinite: false,
            speed: 300,
          });
          [].forEach.call(wrapper.querySelectorAll('[id^="target-review-photos-"]'), (element) => {
            element.addEventListener("shown.bs.modal", function (event) {
              let $slickNode;
              $slickNode = $(event.target).find(".js-slick-photos");
              $slickNode.slick("setPosition");
              if (event.relatedTarget && event.relatedTarget.dataset) {
                $slickNode.slick("slickGoTo", event.relatedTarget.dataset.index);
              }
            });
          });
          swipeForWindows();
          collapseFortravelReviews(wrapper);
        }
      })
      .catch(() => {
        wrapper.innerHTML = `
          <div class="c-no-results">
            <h3 class="c-no-results__heading">サーバーエラー</h3>
            <p class="c-no-results__description">
              ただいまサイトへのアクセスが大変混み合っております。
              <br>
              しばらくたってから再度ご利用ください。
            </p>
          </div>`;
      });
  }

  const fetchButton = document.querySelector(".js-fetch-secondary-reviews");
  if (fetchButton) {
    fetchButton.addEventListener("click", () => {
      fetchSecondaryReviews();
    });
  }
});
