import "slick-carousel"
import { unmaskSlash } from "javascripts/webpage/link";

$ ->
  # 検索ボックスのクリックカウント[sp]
  $('.js-search-box').on 'submit', (e) ->
    e.preventDefault()
    onclickcatalyst_clickcount('search_box', 'event7')
    @.setAttribute('action', unmaskSlash(@.getAttribute('action')))
    @.submit()

  # もっと見るリンク押下
  $('.js-show-more-button').on 'click', (e) ->
    $(@).parent().find('.js-show-more-target').show()
    $(@).hide()

  $(document).on 'click', '.js-click-count', ->
    onclickcatalyst_clickcount($(@).attr('data-click-area'), $(@).attr('data-event'))

  # 指定した範囲を追従させる
  if $('.js-floating').length > 0
    viewport = document.querySelector('.js-floating-viewport')
    floating = document.querySelector('.js-floating[data-floating]')
    floatingBottom = document.querySelector('.js-floating-bottom[data-floating-bottom]')
    targetSection = document.querySelector('.js-floating-hide')
    options =
      if $('#js-aggregated_hotels-show, #js-hotels').length > 0
        rootMargin : '0% 0%'
      else if $('.SmartView #js-restaurants, .SmartView #js-tourspots').length > 0
        rootMargin : '-50% 0%'
      else
        rootMargin : '0% 0% -100% 0%'
    observer = new IntersectionObserver(((entries) ->
      entries.forEach (entry) ->
        if entry.isIntersecting
          floating.classList.add('c-floating--active')
          if floatingBottom
            floatingBottom.classList.add('c-floating--active')
        else
          floating.classList.remove('c-floating--active')
          if floatingBottom
            floatingBottom.classList.remove('c-floating--active')
    ), options)
    observer.observe(viewport)

    # 指定した範囲内で非表示にする
    hiddenObserver = new IntersectionObserver(((entries) ->
      entries.forEach (entry) ->
        if entry.isIntersecting
          floating.classList.remove('c-floating--active')
          if floatingBottom
            floatingBottom.classList.remove('c-floating--active')
        else
          floating.classList.add('c-floating--active')
          if floatingBottom
            floatingBottom.classList.add('c-floating--active')
    ), rootMargin: '-50% 0% 0% 0%')
    if targetSection
      hiddenObserver.observe(targetSection)

  # 口コミパーツ
  $('.js-slick-review-image').slick
    dots: true
  review_images = document.querySelectorAll('[id^="target-review-images-"]')
  review_images.forEach (element) =>
    element.addEventListener 'shown.bs.modal', (event) ->
      $slickNode = $(event.target).find('.js-slick-review-image')
      $slickNode.slick('setPosition')
      $slickNode.slick('slickGoTo', event.relatedTarget.dataset.index)

  # 追従メニューを途中から表示
  if $('.js-sticky').length > 0
    sticky = document.getElementsByClassName('js-sticky')[0]
    target = document.getElementsByClassName('js-sticky-target')[0]
    observer = new IntersectionObserver (entries) ->
      for entry in entries
        sticky.classList.toggle('c-sticky--active', !entry.isIntersecting)
    observer.observe(target)
