import "slick-carousel";
import googlemap from "javascripts/googlemap";
import Rails from "@rails/ujs";
import { unmaskSlash } from "javascripts/webpage/link";

class SpotMap {
  constructor(maps, url) {
    this.maps = maps;
    this.url = unmaskSlash(url);
    this.images = {};
    this.spots = {};
    this.markers = [];
    this.pressParts = document.querySelectorAll(".p-presses-show__part[data-id]");
    this.spotSlugs = [].filter.call(this.pressParts, (node) => /Component::Travel|Component::Tabelog|Component::Tourspot/.test(node.dataset.type)).map((node) => node.dataset.id);
    this.observer = new IntersectionObserver(
      (entries) => {
        entries
          .filter((entry) => entry.isIntersecting)
          .forEach((entry) => {
            this.currentSpotSlug = Object.keys(this.slugs).find((key) => this.slugs[key].includes(entry.target.dataset.id)) || this.spotSlugs[this.spotSlugs.length - 1];
            if (Object.keys(this.markers).length) {
              const marker = Object.values(this.markers).find((marker) => marker.slug === this.currentSpotSlug);
              if (marker) this.maps.event.trigger(marker, "click");
            }
          });
      },
      { rootMargin: "-50% 0px -50% 0px" },
    );
    let slugs = [];
    this.slugs = [].reduce.call(
      this.pressParts,
      (accumulator, node) => {
        this.observer.observe(node);
        slugs.push(node.dataset.id);
        if (this.spotSlugs.includes(node.dataset.id)) {
          accumulator[node.dataset.id] = [...slugs];
          slugs = [];
        }
        return accumulator;
      },
      {},
    );
    this.slickElement = $("#target-spot-map-list")
      .slick()
      .on("beforeChange", (event, slick, current, next) => {
        const spotId = slick.$slides.get(next).querySelector(".p-spot-map__item").dataset.markerId;
        for (const [id, marker] of Object.entries(this.markers)) {
          if (id === spotId) {
            marker.setOptions({ icon: this.iconHash(marker.type, true, marker.selected), zIndex: 2 });
            this.map.panTo(marker.getPosition());
          } else {
            marker.setOptions({ icon: this.iconHash(marker.type, false, marker.selected), zIndex: 1 });
          }
        }
      });
    this.map = new maps.Map(document.getElementById("js-spot-map-canvas"), {
      mapTypeId: maps.MapTypeId.ROADMAP,
      fullscreenControl: false,
      mapTypeControl: false,
      gestureHandling: "greedy",
      streetViewControl: false,
      zoomControl: false,
    });

    this.iconHash = (type, active, selected) => {
      if (selected) {
        return { url: this.images[`${type}_here`], scaledSize: new this.maps.Size(40, 40) };
      } else {
        if (active) {
          return { url: this.images[`${type}_active`], scaledSize: new this.maps.Size(48, 48) };
        } else {
          return { url: this.images[type], scaledSize: new this.maps.Size(32, 32) };
        }
      }
    };

    this.toggleSlide = () => {
      document.body.classList.add("p-slide-panel__in");
      document.body.classList.toggle("p-slide-panel__in--right");
      document.querySelector(".js-slide-panel").classList.toggle("p-slide-panel--open");
    };

    this.infoWindowContent = (spot) => {
      const image_tag = spot.image_url ? `<img class="p-spot-map-infowindow__image" src="${spot.image_url}">` : '<div class="p-spot-map__image--empty"></div>';
      return `
      <div class="p-spot-map-infowindow__container">
        <a href="${spot.url}" target="_blank" class="p-spot-map-infowindow__link">
          <figure class="p-spot-map-infowindow__figure">${image_tag}</figure>
        </a>
        <div class="p-spot-map-infowindow__content">
          <p class="p-spot-map-infowindow__name">${spot.name.slice(0, spot.name.indexOf("（"))}</p>
          <div class="p-spot-map-infowindow__rating">
            <div class="c-rating"><div class="c-rating__star"><i class="c-rating__star--value${Math.floor(spot.score / 0.5)}"></i></div></div>
            <span class="c-rating__score">${spot.score}</span>
          </div>
          <p class="p-spot-map-infowindow__description">${spot.description || ""}</p>
        </div>
      </div>
      `;
    };

    this.makeSpotHtml = (spot, index) => {
      const smartphone = document.documentElement.classList.contains("SmartView");
      const image_tag = spot.image_url ? `<img class="p-spot-map__image" src="${spot.image_url}">` : `<div class="p-spot-map__image--empty"></div>`;
      const score_tag = spot.score
        ? `
        <div class="p-spot-map__rating">
          <div class="c-rating"><div class="c-rating__star"><i class="c-rating__star--value${Math.floor(spot.score / 0.5)}"></i></div></div>
          <span class="c-rating__score">${spot.score}</span>
        </div>
      `
        : "";
      const distance_tag = spot.distance ? `<p class="p-spot-map__distance">${spot.distance}</p>` : "";
      const open_map_app = smartphone
        ? `
        <a target="_blank" rel="noopener" class="p-spot-map__link--app js-click-count" data-click-area="map_google_map" data-event="event16"
          href="https://maps.google.com/maps?q=${spot.latitude},${spot.longitude}">地図アプリで開く</a>
      `
        : "";
      const description_tag = spot.description ? `<p class="p-spot-map__description">${spot.description}</p>` : "";
      const memo_tag = spot.memo
        ? `
        <div class="p-spot-map__memo" data-bs-toggle="modal" data-bs-target="#target-lists-spot-memo-${spot.id}">
          <i class="glyph-memo c-glyph c-glyph--left"></i><span class="p-spot-map__memo--lineclamp">${spot.memo}</span>
        </div>
      `
        : "";

      var relationship = "";
      if (spot.url.match(/cid=icotto/)) relationship = "nofollow ";
      return `
        <div class="p-spot-map__item ${document.getElementById("js-trip_lists") ? "p-spot-map__item--overflow" : ""}" data-marker-id="${spot.id}" data-slide-index="${index}">
          <a href="${spot.url}" target="_blank" rel="${relationship}noopener">${image_tag}</a>
          <a href="${spot.url}" target="_blank" rel="${relationship}noopener" class="p-spot-map__link"><p class="p-spot-map__name">${spot.name}</p></a>
          ${score_tag}${description_tag}${distance_tag}${memo_tag}${open_map_app}
        </div>
      `;
    };

    this.makeMarker = (spot) => {
      const marker = new this.maps.Marker({
        id: spot.id,
        slug: spot.slug,
        type: spot.type,
        selected: spot.selected,
        map: this.map,
        position: new this.maps.LatLng(spot.latitude, spot.longitude),
        icon: this.iconHash(spot.type, false, spot.selected),
        zIndex: spot.selected ? 2 : 1,
      });
      if (marker.selected) {
        const infoWindow = new this.maps.InfoWindow({ content: this.infoWindowContent(spot) });
        marker.addListener("click", () => infoWindow.open(marker.map, marker));
      } else {
        marker.addListener("click", () => {
          const spotItem = document.querySelector(`.p-spot-map__item[data-marker-id="${spot.id}"]`);
          this.slickElement.slick("slickGoTo", spotItem.dataset.slideIndex);
        });
      }
      return marker;
    };

    this.makeMarkers = () => {
      this.bounds = new maps.LatLngBounds();
      Object.values(this.markers).forEach((marker) => marker.setMap(null));
      this.markers = Object.values(this.spots).reduce((accumulator, spot) => {
        accumulator[spot.id] = this.makeMarker(spot);
        this.bounds.extend(accumulator[spot.id].position);
        return accumulator;
      }, {});

      if (Object.keys(this.markers).length > 1) {
        this.map.fitBounds(this.bounds);
        this.map.panToBounds(this.bounds);
      } else if (Object.keys(this.markers).length) {
        this.map.setZoom(15);
        this.map.setCenter(this.markers[Object.keys(this.markers)[0]].position);
      }
    };

    this.reCreateSlick = () => {
      this.slickHtml = Object.values(this.spots)
        .filter((spot) => !spot.selected)
        .map((spot, index) => this.makeSpotHtml(spot, index));

      if (!this.slickHtml.length) this.slickHtml.push(`<div class="p-spot-map__item--empty"><i class="glyph-location c-glyph"></i>20km圏内にスポットがありませんでした</div>`);
      this.slickElement.slick("destroy");
      [].forEach.call(document.getElementsByClassName("js-spot-map-list"), (element) => (element.innerHTML = this.slickHtml.join("")));
      this.slickElement.slick();
    };

    this.getSpots = (type = null) => {
      Rails.ajax({
        url: this.url,
        type: "GET",
        dataType: "json",
        data: Object.entries({ type: type })
          .flatMap((e) => (e[1] ? e.join("=") : []))
          .join("&"),
        error: (response) => {
          console.error(response);
        },
        success: (response) => {
          this.images = response.images || this.images;
          this.spots = response.spots || {};
          this.makeMarkers();
          this.reCreateSlick();
          if (this.currentSpotSlug) {
            const currentMarker = Object.values(this.markers).find((marker) => marker.slug === this.currentSpotSlug);
            if (currentMarker) this.maps.event.trigger(currentMarker, "click");
          }
        },
      });
    };

    this.getFilterdSpots = ({ target }) => {
      const element = target.closest(".js-map-spots-filter");
      if (element.classList.contains("p-spot-map-button__item--selected")) return;

      document.querySelector(".p-spot-map-button__item--selected").classList.remove("p-spot-map-button__item--selected");
      element.classList.add("p-spot-map-button__item--selected");
      // eslint-disable-next-line no-undef
      onclickcatalyst_clickcount(`map-filer-${element.dataset.type}`, "event16");
      this.getSpots(element.dataset.type);
    };

    this.toggleSpotMap = () => {
      if (!Object.keys(this.images).length || !Object.keys(this.spots).length) {
        this.getSpots();
      } else {
        if (this.currentSpotSlug) {
          const currentMarker = Object.values(this.markers).find((marker) => marker.slug === this.currentSpotSlug);
          if (currentMarker) this.maps.event.trigger(currentMarker, "click");
        }
      }

      // SPはbootstrapのmodalで表示される。PCはここで実装
      if (document.querySelector(".js-slide-panel")) this.toggleSlide();
    };

    this.toggleFleshSpotMap = ({ target }) => {
      url = target.dataset.url;
      this.getSpots();

      // SPはbootstrapのmodalで表示される。PCはここで実装
      if (document.querySelector(".js-slide-panel")) this.toggleSlide();
    };

    this.toggleSpotMapCompare = () => {
      if (!Object.keys(this.images).length || !Object.keys(this.spots).length) this.getSpots();

      // SPはbootstrapのmodalで表示される。PCはここで実装
      if (document.querySelector(".js-slide-panel") && !document.querySelector(".p-slide-panel--open")) this.toggleSlide();
    };

    this.currentGeolocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const marker = new this.maps.Marker({
              id: "current",
              position: new this.maps.LatLng(position.coords.latitude, position.coords.longitude),
              map: this.map,
              icon: { url: this.images["here"], scaledSize: new this.maps.Size(40, 40), zIndex: 1 },
            });
            this.bounds.extend(marker.position);
            this.map.fitBounds(this.bounds);
            this.map.panToBounds(this.bounds);
          },
          () => alert("位置情報を取得できませんでした"),
        );
      } else {
        alert("ブラウザは位置情報をサポートしていません");
      }
    };
  }
}

function bindSpotMapEventTriggers() {
  if (!document.querySelector(".js-map-canvas-button[data-url]")) return;

  googlemap.then((maps) => {
    const spotMap = new SpotMap(maps, document.querySelector(".js-map-canvas-button[data-url]").dataset.url);
    [].forEach.call(document.querySelectorAll(".js-map-canvas-button"), (element) => {
      if (element.dataset.slug) {
        element.addEventListener("click", (event) => {
          spotMap.toggleSpotMapCompare();
          spotMap.currentSpotSlug = Object.keys(spotMap.slugs).find((key) => spotMap.slugs[key].includes(event.target.dataset.slug));
          if (Object.keys(spotMap.markers).length) {
            const marker = Object.values(spotMap.markers).find((marker) => marker.slug === spotMap.currentSpotSlug);
            if (marker) spotMap.maps.event.trigger(marker, "click");
          }
        });
      } else if (element.dataset.reflesh) {
        element.addEventListener("click", spotMap.toggleFleshSpotMap);
      } else {
        element.addEventListener("click", spotMap.toggleSpotMap);
      }
    });
    [].forEach.call(document.querySelectorAll(".js-map-spots-position-button"), (element) => {
      element.addEventListener("click", spotMap.currentGeolocation);
    });
    [].forEach.call(document.querySelectorAll(".js-map-spots-filter"), (element) => {
      element.addEventListener("click", spotMap.getFilterdSpots);
    });
  });
}

module.exports = { bindSpotMapEventTriggers };

document.addEventListener("DOMContentLoaded", () => {
  bindSpotMapEventTriggers();
});
