import lozad from "lozad";
import { bindMicromodalEventTrigger } from "javascripts/micromodal";
import { swipeForWindows } from "javascripts/webpage/swipe-for-windows";
import { smoothScroll } from "javascripts/webpage/smooth-scroll";
import { headerScroll } from "javascripts/webpage/sp/header-scroll";
import { bindSearchboxEventTrigger } from "javascripts/webpage/searchbox";
import { unmaskSlash } from "javascripts/webpage/link";
const planSelectionCancelPolicyName = "cancel-policy";
const planSelectionRateNameName = "rate-name";
const planSelectionPaymentmethodName = "paymentmethod";
const planSelectionBedGroupName = "bed-group";
const planSelectionSections = [planSelectionCancelPolicyName, planSelectionRateNameName, planSelectionPaymentmethodName, planSelectionBedGroupName];
const displayNoneClass = "u-display__none";

function showHide(element, condition) {
  if (condition) {
    element.classList.remove(displayNoneClass);
  } else {
    element.classList.add(displayNoneClass);
  }
}

function switchFloating(bedGroupPlan) {
  const plansSelectionModal = bedGroupPlan.closest(".js-plans-selection-modal");
  plansSelectionModal.querySelectorAll(".js-plans-selection-floating").forEach((item) => showHide(item, false));
  const floatingSelector = `.js-plans-selection-floating${dataSelector(bedGroupPlan, planSelectionSections)}`;
  showHide(plansSelectionModal.querySelector(floatingSelector), true);
}

function dataSelector(element, targets) {
  let selector = "";
  targets.forEach((target) => {
    const camelCaseTarget = target.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
    selector = selector + `[data-${target}-index="${element.dataset[camelCaseTarget + "Index"]}"]`;
  });
  return selector;
}

function getNextStepCheckedChoiceWithVisibilityUpdating(checkedChoice, updateTarget) {
  const roomPlanSelectContainer = checkedChoice.closest(".js-plans-selection-container");
  // 一旦全て非表示
  roomPlanSelectContainer.querySelectorAll(`.js-${updateTarget}`).forEach((item) => showHide(item, false));
  const updateTargetSelector = `.js-${updateTarget}${dataSelector(checkedChoice, planSelectionSections.slice(0, planSelectionSections.indexOf(updateTarget)))}`;
  const updateTargetElements = roomPlanSelectContainer.querySelectorAll(updateTargetSelector);
  // グループに紐づく選択肢のみ表示
  updateTargetElements.forEach((item) => showHide(item, true));
  // ベッドオプションが1つのみの場合は非表示
  if (updateTarget == planSelectionBedGroupName) {
    showHide(roomPlanSelectContainer.querySelector(".js-bed-groups-container"), updateTargetElements.length >= 2);
  }
  return roomPlanSelectContainer.querySelector(`${updateTargetSelector} :checked`).closest(`.js-${updateTarget}`);
}

function bindPlanSelectionModalTrigger() {
  // キャンセルポリシー
  document.querySelectorAll(".js-cancel-policy").forEach((cancelPolicy) => {
    cancelPolicy.addEventListener("click", (event) => {
      const checkedRateName = getNextStepCheckedChoiceWithVisibilityUpdating(cancelPolicy, planSelectionRateNameName);
      const checkedPaymentmethod = getNextStepCheckedChoiceWithVisibilityUpdating(checkedRateName, planSelectionPaymentmethodName);
      const checkedBedGroup = getNextStepCheckedChoiceWithVisibilityUpdating(checkedPaymentmethod, planSelectionBedGroupName);
      switchFloating(checkedBedGroup);

      const roomPlanSelectContainer = cancelPolicy.closest(".js-plans-selection-container");
      roomPlanSelectContainer.querySelector(".js-cancel-policy-detail.is-open").classList.remove("is-open");
      event.currentTarget.querySelector(".js-cancel-policy-detail").classList.add("is-open");
    });
  });

  // 追加オプション
  document.querySelectorAll(".js-rate-name").forEach((rateName) => {
    rateName.addEventListener("click", () => {
      const checkedPaymentmethod = getNextStepCheckedChoiceWithVisibilityUpdating(rateName, planSelectionPaymentmethodName);
      const checkedBedGroup = getNextStepCheckedChoiceWithVisibilityUpdating(checkedPaymentmethod, planSelectionBedGroupName);
      switchFloating(checkedBedGroup);
    });
  });

  // 支払い方法
  document.querySelectorAll(".js-paymentmethod").forEach((paymentmethod) => {
    paymentmethod.addEventListener("click", () => {
      const checkedBedGroup = getNextStepCheckedChoiceWithVisibilityUpdating(paymentmethod, planSelectionBedGroupName);
      switchFloating(checkedBedGroup);
    });
  });

  // ベッドオプション
  document.querySelectorAll(".js-bed-group").forEach((bedGroup) => {
    bedGroup.addEventListener("click", () => {
      switchFloating(bedGroup);
    });
  });
}

function pollHotels(action, searchParams, isPageLoad) {
  let pollingUrl;
  if (action && searchParams) {
    pollingUrl = `${action}?${searchParams}`;
  } else {
    pollingUrl = document.querySelector(".js-polling-url").dataset.pollingUrl;
  }
  fetch(unmaskSlash(pollingUrl))
    .then((response) => {
      if (!response.ok) {
        throw "ErrorException";
      }
      return response.json();
    })
    .then((data) => {
      if (data.html) {
        const searchRoomPlans = document.querySelector(".js-search-room-plans");
        searchRoomPlans.innerHTML = data.html;
        const searchRoomPlanConditions = document.querySelector(".js-plan-searchbox");
        if (!isPageLoad && searchRoomPlanConditions && data.plan_conditions) {
          searchRoomPlanConditions.innerHTML = data.plan_conditions;
          const searchList = document.querySelector(".js-plan-searchbox");
          bindMicromodalEventTrigger(searchList);
          bindSearchboxEventTrigger();
          history.pushState(null, null, `?${searchParams}`);
        }
        const addedElements = searchRoomPlans.querySelectorAll(".js-lazy:not([data-loaded])");
        const newObserver = lozad(addedElements, {
          rootMargin: "200% 400%",
        });
        newObserver.observe();
        bindMicromodalEventTrigger(searchRoomPlans);

        bindPlanSelectionModalTrigger();
        swipeForWindows();
        smoothScroll();
        headerScroll();
        setRoomId();
        scrollToRoom();
        setNoPlanCatalyst();
      } else {
        setTimeout(pollHotels(action, searchParams, isPageLoad), 500);
      }
    })
    .catch(() => {
      document.querySelector(".js-search-room-plans").innerHTML = `
        <div class="c-no-results">
          <h3 class="c-no-results__heading">サーバーエラー</h3>
          <p class="c-no-results__description">
            ただいまサイトへのアクセスが大変混み合っております。
            <br>
            しばらくたってから再度ご利用ください。
          </p>
        </div>`;
    });
}

function setRoomId() {
  document.querySelectorAll(".js-open-calendar").forEach((element) => {
    element.addEventListener("click", () => {
      document.querySelector(".js-search-room-id-input").value = element.dataset.roomId ? element.dataset.roomId : null;
    });
  });
}

function scrollToRoom() {
  const roomId = document.querySelector(".js-search-room-id-input").value;
  const selectedRoom = document.getElementById(roomId);
  if (!selectedRoom) {
    return;
  }
  if (selectedRoom.classList.contains("js-details-room")) {
    document.querySelector(".js-details-room-summary").click();
  }
  selectedRoom.scrollIntoView({ block: "center" });
  const openPlanModalButton = selectedRoom.getElementsByClassName("js-open-plan-modal")[0];
  if (openPlanModalButton) {
    openPlanModalButton.click();
  }
  document.querySelector(".js-search-room-id-input").value = null;
}

function setNoPlanCatalyst() {
  if (document.querySelector(".js-search-room-plans-nohit")) {
    // eslint-disable-next-line no-undef
    catalyst_plansearch_nohit();
  }
}

document.addEventListener("DOMContentLoaded", () => {
  const searchRoomPlans = document.querySelector(".js-search-room-plans");
  if (searchRoomPlans && searchRoomPlans.dataset.displayCachePlan == "false") {
    const searchRoomPlanSkeleton = document.querySelector(".js-search-rooms-skeleton");
    searchRoomPlans.innerHTML = searchRoomPlanSkeleton.innerHTML;
    pollHotels(null, null, true);
  }
});

export { pollHotels };
