import { pollHotels } from "javascripts/webpage/loader-room-plans";
import { unmaskSlash } from "javascripts/webpage/link";

document.addEventListener("DOMContentLoaded", () => {
  const searchButtons = document.querySelectorAll(".js-search-button");
  const searchRefineSortButtons = document.querySelectorAll(".js-search-refine-sort-button");
  const searchConditionsForm = document.querySelector(".js-search-conditions-form");
  const searchRoomPlanConditions = document.querySelector(".js-plan-searchbox");
  let destinationInputs, searchTagInput;
  function bindSearchTrigger(element) {
    if (element) {
      const searchPlan = (e) => {
        e.preventDefault();

        if (searchRoomPlanConditions && searchRoomPlanConditions.dataset.isAsync) {
          const searchRoomPlans = document.querySelector(".js-search-room-plans");
          if (searchRoomPlans) {
            const searchRoomPlanSkeleton = document.querySelector(".js-search-rooms-skeleton");
            searchRoomPlans.innerHTML = searchRoomPlanSkeleton.innerHTML;

            search(element.dataset.searchUrl, element.dataset.combineForms);
          }
        } else {
          search(element.dataset.searchUrl, element.dataset.combineForms);
        }
      };
      element.addEventListener("click", searchPlan);
      element.addEventListener("touchend", searchPlan);
    }
  }
  if (searchConditionsForm) {
    destinationInputs = searchConditionsForm.querySelectorAll(".js-search-destination-input");
    searchTagInput = searchConditionsForm.querySelector(".js-search-tag-input");
    searchButtons.forEach((button) => bindSearchTrigger(button));
    searchRefineSortButtons.forEach((button) => bindSearchTrigger(button));
    const nodateButton = document.querySelector(".js-nodate-button");
    if (nodateButton) {
      const searchNodatePlan = () => {
        search(nodateButton.dataset.searchUrl, nodateButton.dataset.combineForms);
        nodateButton.style.display = "none";
      };
      nodateButton.addEventListener("click", searchNodatePlan);
      nodateButton.addEventListener("touchend", searchNodatePlan);
      if (nodateButton.dataset.isNodate) {
        nodateButton.style.display = "none";
      }
    }
    const checkInputObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type == "attributes" && mutation.attributeName == "value") {
          checkInputs();
        }
      });
    });
    destinationInputs.forEach((input) => {
      checkInputObserver.observe(input, { attributes: true });
    });
    Array.from(searchConditionsForm.querySelectorAll(".js-search-required-input")).forEach((input) => {
      checkInputObserver.observe(input, { attributes: true });
    });
    checkInputObserver.observe(searchTagInput, { attributes: true });
    bindSearchboxEventTrigger();
    checkInputs();
  }
});

function search(action, combineForms) {
  const searchRoomPlanConditions = document.querySelector(".js-plan-searchbox");
  const combineFormsArray = combineForms.split(",");
  let searchParamsArray = [];
  if (combineFormsArray.includes("search")) searchParamsArray.push(searchParams());
  if (combineFormsArray.includes("refine")) searchParamsArray.push(refineParams());
  if (combineFormsArray.includes("sort")) searchParamsArray.push(sortParams());
  if (combineFormsArray.includes("nodate")) searchParamsArray.push("no_date=true");
  if (searchRoomPlanConditions && searchRoomPlanConditions.dataset.isAsync) {
    const searchRoomPlans = document.querySelector(".js-search-room-plans");
    if (searchRoomPlans) {
      const searchRoomPlanSkeleton = document.querySelector(".js-search-rooms-skeleton");
      searchRoomPlans.innerHTML = searchRoomPlanSkeleton.innerHTML;

      pollHotels(unmaskSlash(action), searchParamsArray.filter(Boolean).join("&"));
      if (!combineFormsArray.includes("nodate")) document.querySelector(".js-nodate-button").style.display = "flex";
    }
  } else {
    window.location.href = `${unmaskSlash(action)}?${searchParamsArray.filter(Boolean).join("&")}`;
  }
}

function searchParams() {
  const searchConditionsForm = document.querySelector(".js-search-conditions-form");
  const formData = new FormData(searchConditionsForm);
  formData.delete(searchConditionsForm.querySelector(".js-child").name);
  formData.append("children", formData.getAll("children[]").join(","));
  formData.delete("children[]");
  return new URLSearchParams(formData).toString();
}

function refineParams() {
  const refineForm = document.querySelector(".js-refine-form");
  if (refineForm) {
    const formData = new FormData(refineForm);
    const planConditions = [];
    const hotelTypes = [];
    const hotelConditions = [];

    if (document.querySelector(".js-plan-conditions")) {
      document.querySelectorAll("input.js-plan-conditions:checked").forEach((input) => {
        planConditions.push(input.value);
      });
      document.querySelectorAll("select.js-plan-conditions").forEach((select) => {
        if (select.value.length > 0) {
          planConditions.push(select.value);
        }
      });
      formData.delete("plan_conditions[]");
      formData.append("plan_conditions", planConditions.join(","));
    }
    if (document.querySelector(".js-hotel-conditions")) {
      document.querySelectorAll("input.js-hotel-conditions:checked").forEach((input) => {
        hotelConditions.push(input.value);
      });
      document.querySelectorAll("select.js-hotel-conditions").forEach((select) => {
        hotelConditions.push(select.value);
      });
      formData.delete("hotel_conditions[]");
      formData.append("hotel_conditions", hotelConditions.join(","));
    }
    if (document.querySelector("input.js-hotel-types")) {
      document.querySelectorAll("input.js-hotel-types:checked").forEach((input) => {
        hotelTypes.push(input.value);
      });
      formData.delete("hotel_types[]");
      formData.append("hotel_types", hotelTypes.join(","));
    }

    return new URLSearchParams(formData).toString();
  } else {
    return "";
  }
}

function sortParams() {
  const sortForm = document.querySelector(".js-sort-form");
  if (sortForm) {
    const formData = new FormData(sortForm);
    return new URLSearchParams(formData).toString();
  } else {
    return "";
  }
}

function toggleSearchButtonState(buttons, valid) {
  if (valid) {
    buttons.forEach((button) => {
      button.removeAttribute("disabled");
      button.classList.remove("c-searchbox__button--disabled");
    });
  } else {
    buttons.forEach((button) => {
      button.setAttribute("disabled", true);
      button.classList.add("c-searchbox__button--disabled");
    });
  }
}

function checkInputs() {
  const searchConditionsForm = document.querySelector(".js-search-conditions-form");
  const searchTagInput = searchConditionsForm.querySelector(".js-search-tag-input");
  const destinationInputs = searchConditionsForm.querySelectorAll(".js-search-destination-input");
  const searchButtons = document.querySelectorAll(".js-search-button");
  const searchRefineSortButtons = document.querySelectorAll(".js-search-refine-sort-button");
  if (searchConditionsForm) {
    const searchTagPresented = searchTagInput.dataset.searchTagPresented;
    const isHotelPlansSearch = searchTagInput.dataset.hotelPlansSearch;
    const destinationFilled = Array.from(destinationInputs).some((input) => input.value.trim() !== "") || searchTagPresented == "true" || isHotelPlansSearch == "true";
    const staydatesFilled = document.querySelector(".js-search-checkin-input").value.trim() !== "" && document.querySelector(".js-search-checkout-input").value.trim() !== "";
    const requiredFilled = Array.from(searchConditionsForm.querySelectorAll(".js-search-required-input:not([disabled])")).every((input) => input.value.trim() !== "");
    const adult = searchConditionsForm.querySelector(".js-adult").value;
    const searchButtonMessages = document.querySelectorAll(".js-search-button-message");
    let needToFills = [];
    let searchMessage = "";
    if (!destinationFilled) {
      needToFills.push("目的地");
    }
    if (!staydatesFilled) {
      needToFills.push("宿泊日");
    }
    if (needToFills.length != 0) {
      searchMessage = `※${needToFills.join("、")}を設定してください`;
      searchButtonMessages.forEach((searchButtonMessage) => {
        searchButtonMessage.innerHTML = searchMessage;
        searchButtonMessage.classList.add("c-searchbox__message");
        searchButtonMessage.classList.remove("u-display__none");
      });
    } else {
      searchButtonMessages.forEach((searchButtonMessage) => {
        searchButtonMessage.innerHTML = "";
        searchButtonMessage.classList.remove("c-searchbox__message");
        searchButtonMessage.classList.add("u-display__none");
      });
    }
    const valid = destinationFilled && requiredFilled && adult > 0;
    toggleSearchButtonState(searchButtons, valid);
    if (searchConditionsForm.dataset.realtimeSearch === "true") {
      toggleSearchButtonState(searchRefineSortButtons, valid);
    }
  }
}

function bindSearchboxEventTrigger() {
  const searchConditionsForm = document.querySelector(".js-search-conditions-form");
  const destinationInputs = searchConditionsForm.querySelectorAll(".js-search-destination-input");
  const searchTagInput = searchConditionsForm.querySelector(".js-search-tag-input");
  document.querySelectorAll(".js-search-tag").forEach((element) => {
    element.addEventListener("click", () => {
      searchTagInput.setAttribute("value", "");
      searchTagInput.setAttribute("name", "");
      searchTagInput.dataset.searchTagPresented = false;
      if (element.dataset.prefectureId) {
        document.querySelector(`.js-search-destination-prefectures-input`).value = element.dataset.prefectureIds;
      }
      if (element.dataset.areaIds) {
        document.querySelector(`.js-search-destination-areas-input`).value = element.dataset.areaIds;
      }
      destinationInputs.forEach((destinationInput) => {
        destinationInput.removeAttribute("disabled");
      });
      search(element.dataset.searchUrl, element.dataset.combineForms);
    });
  });

  document.querySelectorAll(".js-search-tag-target").forEach((searchTagTarget) => {
    searchTagTarget.addEventListener("click", () => {
      destinationInputs.forEach((destinationInput) => {
        destinationInput.setAttribute("disabled", true);
      });
      document.querySelectorAll(".js-destination-text").forEach((element) => {
        element.classList.add("u-display__none");
      });
      document.querySelectorAll(".js-search-tag").forEach((element) => {
        element.classList.remove("u-display__none");
      });
      document.querySelectorAll(".js-search-tag-title").forEach((searchTagTitle) => {
        searchTagTitle.innerHTML = searchTagTarget.dataset.title;
      });
      searchTagInput.dataset.searchTagPresented = true;
      searchTagInput.setAttribute("value", searchTagTarget.dataset.value);
      searchTagInput.setAttribute("name", searchTagTarget.dataset.key);
    });
  });

  document.querySelectorAll(".js-search-button-refine-link").forEach((element) => {
    element.addEventListener("click", () => {
      const targetRefine = document.getElementById(element.dataset.code);
      if (targetRefine) {
        targetRefine.checked = !targetRefine.checked;
      } else if (element.dataset.code == "price") {
        document.querySelectorAll("input.js-search-refine-price").forEach((input) => {
          input.value = "";
        });
      } else {
        document.querySelector(`option[value=${element.dataset.code}]`).closest("select").value = "";
      }
      search(element.dataset.searchUrl, element.dataset.combineForms);
    });
  });
}

export { bindSearchboxEventTrigger };
