import googlemap from "javascripts/googlemap";
import { unmaskSlash } from "javascripts/webpage/link";

document.addEventListener("DOMContentLoaded", () => {
  const canvas = document.getElementById("map-canvas");
  if (!canvas) {
    return;
  }
  const initMaps = () => {
    const options = {
      rootMargin: "50%",
      threshold: 0,
    };
    const observer = new IntersectionObserver(function (entries, observer) {
      const isIntersecting = entries[0].isIntersecting;
      if (isIntersecting) {
        googlemap.then((maps) => {
          const latlng = new maps.LatLng(canvas.dataset.lat, canvas.dataset.lng);
          const map = new maps.Map(canvas, {
            zoom: 13,
            fullscreenControl: false,
            center: latlng,
            gestureHandling: "none",
            mayTypeId: maps.MapTypeId.ROADMAP,
            mapTypeControl: false,
            scaleControl: true,
            streetViewControl: false,
            zoomControl: false,
            clickableIcons: false,
            draggableCursor: "default",
          });
          new maps.Marker({ position: latlng, map: map, icon: { url: unmaskSlash(canvas.dataset.imageUrl), scaledSize: new maps.Size(40, 40) } });
        });
        observer.unobserve(canvas);
      }
    }, options);
    observer.observe(canvas);
  };
  initMaps();
});
