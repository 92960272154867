import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: SENTRY_DSN,
  release: COMMIT_HASH,
  denyUrls: [/extensions\//i, /^chrome:\/\//i, /instagram\.com/i, /twitter\.com/i, /amazon-adsystem/i, /s\.yimg\.jp/i],
  ignoreErrors: [
    /can't execute code from a freed script/i,
    /securityerror: blocked a frame with origin/i,
    /can't find variable: (yahoo|gmo)/i,
    "Function expected",
  ],
  autoSessionTracking: false,
  enableTracing: false,
});
