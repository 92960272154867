document.addEventListener("DOMContentLoaded", () => {
  const reviews = document.querySelectorAll(".js-press-parts-fortravel-review-showmore");
  if (reviews.length > 0) {
    const collapseMinLines = 4;
    reviews.forEach((element) => {
      const description = element.querySelector(".p-presses-show-fortravel-review-user__description");
      if (description && description.scrollHeight >= collapseMinLines * parseFloat(window.getComputedStyle(description).lineHeight)) {
        element.classList.add("p-presses-show-fortravel-review--hide");
        element.insertAdjacentHTML(
          "beforeend",
          "<span class='p-presses-show-fortravel-review-showmore-button js-collapse-hotel-parts-fortravel-review' data-content='続きを見る'></span>",
        );
      }
    });

    document.querySelectorAll(".js-collapse-hotel-parts-fortravel-review").forEach((element) => {
      element.addEventListener("click", () => {
        element.style.display = "none";
        element.closest(".p-presses-show-fortravel-review-showmore").classList.remove("p-presses-show-fortravel-review--hide");
      });
    });
  }
});
