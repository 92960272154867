import lozad from "lozad";
import { swipeForWindows } from "javascripts/webpage/swipe-for-windows";
import { unmaskSlash } from "javascripts/webpage/link";

document.addEventListener("DOMContentLoaded", () => {
  function fetchHotelRestaurants(wrapper) {
    fetch(unmaskSlash(wrapper.dataset.fetchUrl), { headers: { "X-Requested-With": "XMLHttpRequest" } })
      .then((response) => {
        if (!response.ok) {
          throw "ErrorException";
        }
        return response.json();
      })
      .then((data) => {
        if (data.html) {
          wrapper.innerHTML = data.html;
          const addedElements = wrapper.querySelectorAll(".js-lazy:not([data-loaded])");
          const newObserver = lozad(addedElements, {
            rootMargin: "200% 400%",
          });
          newObserver.observe();
          swipeForWindows();
        } else {
          setTimeout(fetchHotelRestaurants, 500);
        }
      })
      .catch(() => {
        wrapper.innerHTML = `
          <div class="c-no-results">
            <h3 class="c-no-results__heading">サーバーエラー</h3>
            <p class="c-no-results__description">
              ただいまサイトへのアクセスが大変混み合っております。
              <br>
              しばらくたってから再度ご利用ください。
            </p>
          </div>`;
      });
  }
  const hotelRestaurantWrapper = document.querySelector(".js-hotel-restaurant-wrapper");
  if (hotelRestaurantWrapper) {
    hotelRestaurantWrapper.innerHTML += '<div class="p-hotels-shared-loader js-loader">レストランを取得中です</div>';
    window.addEventListener(
      "scroll",
      () => {
        fetchHotelRestaurants(hotelRestaurantWrapper);
      },
      { once: true },
    );
  }
});
